
import {ref} from "vue"
import useEmitter from "@/composables/UseEmitter.js"

import FormEditHeader from "@/common/form_edit/FormEditHeader.vue"
import FormEdit from "@/common/form_edit/FormEdit.vue"

export default {
	name: "QuickEdit",
	components: {FormEditHeader, FormEdit},
	props: {visibility: Boolean},
	emits: ['hide-quickedit'],
	setup(props, {emit}) {
		const emitter = useEmitter()

		let visibleClass = ref(props.visibility)

		function save() {
			let params = {
				timeDiff: null
			}
			emitter.emit("save-formedit", params)
		}

		function hide(event) {
			emit('hide-quickedit', event)
		}

		return {visibleClass, save, hide}
	}
}
