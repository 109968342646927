
import {onMounted, onUnmounted, ref} from "vue"
import {mapActions} from "vuex"
import store from "@/store"
import router from "@/router"
import useEmitter from "@/composables/UseEmitter"
import moment from "moment"

import FormHeader from "@/components/FormHeader.vue"
import QuickEdit from "@/common/form_edit/QuickEdit.vue"

export default {
	name: "Start",
	components: {FormHeader, QuickEdit},
	setup() {
		const emitter = useEmitter()
		let activeRow = ref(null)
		let expands = ref([])
		let locations = ref([])
		let submission = ref("")
		const startDate = ref("")
		const endDate = ref("")
		let quickEditKey = ref(0)
		let quickEditVisible = ref(false)


		// initialize form record from storage
		store.dispatch("form_data/loadFormRecordList")
		store.dispatch("form_data/patchFormRecords")
		let formRecordList = ref(store.getters["form_data/getFormRecordList"])
		//let filtereredFormRecordList = formRecordList;

		startDate.value = moment().subtract(3, 'days').format("YYYY-MM-DD")
		endDate.value = moment().format("YYYY-MM-DD")
		onSearch()

		function startNewStop(event) {
			// if(!localStorage.getItem('experience') || !localStorage.getItem('assignment') || 
			// localStorage.getItem('experience') == null || localStorage.getItem('assignment') == null ||
			// localStorage.getItem('experience') == 'null' || localStorage.getItem('assignment') == 'null'){
			// 	console.log('experience or assignment empty')
			// 	return
			// }
			if (!store.getters["profile/isValid"]) {
				store.dispatch(
					"dialog/setMessage",
					"Invalid Profile. Please update your profile to continue."
				)
				store.dispatch("dialog/setIconType", "has-text-danger")
				store.dispatch("dialog/setIcon", "fa-exclamation-triangle")
				store.dispatch("dialog/setButtonLeftText", "CANCEL")
				store.dispatch("dialog/setButtonLeftFunc", () => {
					store.dispatch("dialog/closeDialog")
				})
				store.dispatch("dialog/setButtonRightText", "EDIT PROFILE")
				store.dispatch("dialog/setButtonRightFunc", () => {
					store.dispatch("dialog/closeDialog")
					router.push("/profile")
				})
				store.dispatch("dialog/setButtonXText", "X")
				store.dispatch("dialog/setButtonXFunc", () => {
					store.dispatch("dialog/closeDialog")
				})
				store.dispatch("dialog/openDialog")

				return
			}
			if (!store.getters["form_data/isLoaded"]) {
				store.dispatch("form_data/loadSchema", "000001")
			}
			store.dispatch("location/clear_city_street", "")
			store.dispatch("form/setFormId", "000001")
			store.dispatch("form_data/createFormRecord")

			localStorage.setItem('startTime', JSON.stringify(moment()))
			router.push("/form/000001/page/0")
		}

		function clickedSubTableRow(event) {
			event.stopPropagation()

			let selectedRow = event.currentTarget.dataset.row
			let selectedPerson = event.currentTarget.dataset.person
			
			if (store.dispatch("form_data/setActiveFormRecord", selectedRow)
				&& store.dispatch("form_data/setActivePersonRecord", selectedPerson)) {

				let formData = store.getters["form_data/getFormRecord"]
				if (store.getters["form_data/getActiveFormRecordStatus"] !== "Saved") {
					store.dispatch("form_data/setItemValue", {
						"store":"",
						"key":"status",
						"value":"Not Saved"
					})
				}

				store.dispatch("submission/convert", formData).then((result) => {
					store.dispatch("submission/set", result).then(() => {
						quickEditKey.value = Math.random()
						quickEditVisible.value = true
					})
				})
			}
		}

		function toggleSubTableRow(event) {
			event.stopPropagation()
			let selectedRow = event.currentTarget.dataset.row
			expands.value[selectedRow] = !expands.value[selectedRow]
		}

		function removeStop(event) {
			event.stopPropagation()

			let selectedRow = event.currentTarget.dataset.row

			if (store.dispatch("form_data/setActiveFormRecord", selectedRow)) {
				store.dispatch("form_data/deleteFormRecord")
				onSearch()
			}
		}

		function duplicateStop(event) {
			event.stopPropagation()

			let selectedRow = event.currentTarget.dataset.row
			if (store.dispatch("form_data/setActiveFormRecord", selectedRow)) {
				store.dispatch("form_data/duplicateFormRecord")
				onSearch()
			}
		}

		function addPerson(event) {
			event.stopPropagation()

			let selectedRow = event.currentTarget.dataset.row

			if (store.dispatch("form_data/setActiveFormRecord", selectedRow)) {
				store.dispatch("form_data/createPersonRecord")
				onSearch()
			}
		}

		function duplicatePerson(event) {
			event.stopPropagation()

			let selectedRow = event.currentTarget.dataset.row
			let selectedPerson = event.currentTarget.dataset.person
			if (store.dispatch("form_data/setActiveFormRecord", selectedRow)
			&& store.dispatch("form_data/setActivePersonRecord", selectedPerson)) {
				store.dispatch("form_data/duplicatePersonRecord")
			}
		}

		function removePerson(event) {
			event.stopPropagation()

			let selectedRow = event.currentTarget.dataset.row
			let selectedPerson = event.currentTarget.dataset.person
			if (store.dispatch("form_data/setActiveFormRecord", selectedRow)
			&& store.dispatch("form_data/setActivePersonRecord", selectedPerson)) {
				store.dispatch("form_data/deletePersonRecord")
			}
		}

		function hideQuickEdit() {
			quickEditVisible.value = false
		}

		for (let rowIndex = 0; rowIndex < formRecordList.value.length; ++rowIndex) {
			expands.value[rowIndex] = false
			locations.value[rowIndex] = store.getters["form_data/getLocation"](rowIndex)
		}

		function getLocation(rowIndex) {
			return store.getters["form_data/getLocation"](rowIndex)
		}

		function formatDateTime(date, time) {
			if (!date) {
				return ''
			}

			if (/\d{2}\/\d{2}\/\d{4}/.test(date)) {
				date = date.replace(/(.*)\/(.*)\/(.*)/, '$3-$1-$2')
			}

			let dateObject = moment(date + ', ' + time, "YYYY-MM-DD, HH:mm")

			return dateObject.format("MM/DD/YYYY HH:mm")
		}

		function onSearch() {
			let startDateParam = startDate.value.trim()
			let endDateparam = endDate.value.trim()
			if(!startDateParam && !endDateparam){
				return
			}
			
			let allFormRecordList = ref(store.getters["form_data/getFormRecordList"])
			allFormRecordList.value.sort((a, b) => b.index - a.index)
			
			formRecordList.value = allFormRecordList.value.filter(d => {
				return (moment(d.date) >= moment(startDateParam) && moment(d.date) <= moment(endDateparam)) || d.date == null
            });
			
		}
		function onSearchClear() {
			let allFormRecordList = ref(store.getters["form_data/getFormRecordList"])
			formRecordList.value = allFormRecordList.value
			//formRecordList.value.sort((a, b) => b.date - a.date)
			formRecordList.value.sort((a, b) => b.index - a.index)
			startDate.value = ''
			endDate.value = ''
		}

		return {
			formRecordList,
			//filtereredFormRecordList,
			expands,
			onSearchClear,
			locations,
			startDate,
			endDate,
			submission,
			quickEditKey,
			quickEditVisible,
			formatDateTime,
			getLocation,
			hideQuickEdit,
			startNewStop,
			clickedSubTableRow,
			toggleSubTableRow,
			removeStop,
			addPerson,
			duplicatePerson,
			removePerson,
			duplicateStop,
			onSearch
		}
	},
	methods: {
		...mapActions("dialog", ["openDialog", "closeDialog",
			"setIcon", "setIconType", "setMessage",
			"setButtonLeftText", "setButtonLeftFunc",
			"setButtonRightText", "setButtonRightFunc",
			"setButtonXText", "setButtonXFunc"])
	},
	mounted() {
		// this.setMessage("Select an Action")
		// this.setIconType("has-text-danger")
		// this.setIcon("fa-exclamation-triangle")
		// // this.setIcon("fa-spinner fa-pulse")
		// this.setButtonLeftText("CANCEL")
		// this.setButtonLeftFunc(() => {
		// 	this.closeDialog()
		// })
		// this.setButtonRightText("CONTINUE")
		// this.setButtonRightFunc(() => {
		// 	this.closeDialog()
		// })
		// this.setButtonXText("X")
		// this.setButtonXFunc(() => {
		// 	this.closeDialog()
		// })
	}
}
