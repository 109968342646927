import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEditHeader = _resolveComponent("FormEditHeader")!
  const _component_FormEdit = _resolveComponent("FormEdit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["quickEdit", {'is-active': $setup.visibleClass}])
  }, [
    _createVNode(_component_FormEditHeader, {
      save_action: $setup.save,
      close_action: $setup.hide
    }, null, 8, ["save_action", "close_action"]),
    _createVNode(_component_FormEdit)
  ], 2))
}